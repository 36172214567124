import { useEffect, useState } from "react";
import { Article } from "../model/article";
import { toImage } from "../transformers/transformToImage";
import { backendUrl, Endpoints } from "../utils/constants";
import useFetch from "./useFetch";

export const useFindAllArticles = () => {
  const { response, error, loading } = useFetch(
    backendUrl + Endpoints.Articles + "?populate=*"
    // {
    //   headers: new Headers({
    //     Authorization: "Bearer " + process.env.REACT_APP_BEARER_TOKEN,
    //     "Access-Control-Allow-Methods": "GET",
    //   }),
    // }
  );
  const [articles, setArticles] = useState<Article[]>();

  useEffect(() => {
    if (response) {
      const articlesFromResponse: Article[] = response.data.map(
        (entry: any) =>
          ({
            id: entry.id,
            uid: entry.attributes.uid,
            title: entry.attributes.title,
            subtitle: entry.attributes.subtitle,
            content: entry.attributes.content,
            date: new Date(entry.attributes.createdAt),
            image: toImage(entry.attributes.image),
            author: entry.attributes.author,
          } as Article)
      );
      setArticles(
        articlesFromResponse.sort((a, b) => {
          if (a.date > b.date) {
            return -1;
          }
          return 1;
        })
      );
    }
  }, [response]);

  return { articles, error, loading };
};
