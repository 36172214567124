import { useEffect, useState } from "react";
import { Time } from "../model/time";

export const assignClasses = (
  firstClass: string,
  secondClass: string,
  condition?: boolean
) => {
  return condition ? `${firstClass} ${secondClass}` : firstClass;
};

export const convertDateToString = (date: Date) =>
  date.toLocaleDateString("hu", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

export const convertDateToStringShort = (date: Date) => {
  const day = date.toLocaleDateString("hu", {
    weekday: "long",
  });
  return day.charAt(0).toUpperCase() + day.slice(1);
};

export const convertDayToString = (date: Date) => {
  const dayString = date.toLocaleDateString("hu", {
    weekday: "long",
  });
  const dateString = date.toLocaleDateString("hu", {
    month: "long",
    day: "numeric",
  });
  return (
    dateString.charAt(0).toUpperCase() + dateString.slice(1) + ", " + dayString
  );
};

export const toTime = (time: string): Time => {
  const timeParts = time.split(":");
  const firstPart = parseInt(timeParts[0]);
  return {
    hour: firstPart < 5 ? firstPart + 24 : firstPart,
    minutes: parseInt(timeParts[1]),
  };
};

export const convertTimeToString = (time: Time): string =>
  `${time.hour >= 24 ? time.hour - 24 : time.hour}:${
    time.minutes === 0 ? "00" : time.minutes
  }`;

export function range(start: number, end: number): number[] {
  start = Math.floor(start);
  end = Math.floor(end);

  const diff = end - start;
  if (diff === 0) {
    return [start];
  }

  const keys = Array(Math.abs(diff) + 1).keys();
  return Array.from(keys).map((x) => {
    const increment = end > start ? x : -x;
    return start + increment;
  });
}

interface WindowSize {
  width: number;
  height: number;
  isMobile: boolean;
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSize>();
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      if (window) {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
          isMobile: window.innerWidth < 800,
        });
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
