import React from "react";
import { createUseStyles } from "react-jss";
import MenuBar from "../components/MenuBar";
import Page from "../components/Page";
import { useGetAbout } from "../hooks/useGetAbout";
import { themeColors } from "../theme";
import { PageRoutes } from "../utils/routes";

const useStyles = createUseStyles({
  title: {
    fontSize: 24,
    fontWeight: "600",
    alignSelf: "flex-start",
    flexGrow: 1,
    lineHeight: 2,
    color: themeColors.blue,
    marginBottom: -8,
    "@media only screen and (max-width: 700px)": {
      marginTop: 40,
    },
  },
  aboutContainer: {
    maxWidth: 1280,
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    width: "100%",
    "@media only screen and (max-width: 700px)": {
      padding: 24,
    },
  },
  paragraph: {
    color: themeColors.blue,
    fontSize: 18,
    lineHeight: "26px",
  },
});

const Tickets = () => {
  const { about, loading, error } = useGetAbout();
  const classes = useStyles();
  return (
    <>
      <Page>
        <MenuBar selectedKey={PageRoutes.Tickets} />

        <div className={classes.aboutContainer}>
          {" "}
          <p className={classes.paragraph}>
            <span>
              Az előzetes regisztráció lezárult. A helyszínen van lehetőség heti
              és napi belépők vásárlására.
            </span>
          </p>
          <span className={classes.title}>Heti belépők</span>
          <p className={classes.paragraph}>
            <span style={{ backgroundColor: themeColors.tintOrange }}>
              Heti bérletek <span style={{ fontWeight: 800 }}>160 lejért</span>
            </span>{" "}
            vásárolhatóak a helyszínen, a regisztrációs pontnál. Az ár
            tartalmazza a sátorhelyet is.
          </p>
          <span className={classes.title} style={{ marginTop: 32 }}>
            Napi belépők
          </span>
          <p className={classes.paragraph}>
            <span style={{ backgroundColor: themeColors.tintPurple }}>
              Vasárnap és csütörtökön{" "}
              <span style={{ fontWeight: 800 }}>ingyenes</span> a belépés
            </span>
            , míg{" "}
            <span style={{ backgroundColor: themeColors.tintYellow }}>
              hétfőn, kedden és szerdán{" "}
              <span style={{ fontWeight: 800 }}>60 lejért</span>
            </span>{" "}
            lehet napijegyet kiváltani a regisztrációs pontnál.
            <br></br>
            <span>
              A helyszínen nem lehet étkezést igényelni, viszont lehetőség lesz
              a Fesztivál területén belül árusoktól különböző finomságokat
              vásárolni.
            </span>
          </p>
        </div>
      </Page>
    </>
  );
};

export default Tickets;
