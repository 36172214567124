import { createUseStyles } from "react-jss";
import { isPropertySignature } from "typescript";
import { Url } from "url";
import { useFindAllSponsors } from "../hooks/useFindAllSponsors";
import { themeColors } from "../theme";
import Spinner from "./Spinner";

const useStyles = createUseStyles({
  sponsors: {
    backgroundColor: themeColors.white,
    padding: 40,
    display: "flex",
    flexDirection: "column",
    paddingBottom: 200,
    marginBottom: -48,
    marginTop: 48,
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
    alignSelf: "center",
    textAlign: "center",
  },
  sponsorLogoContainer: {
    maxHeight: 60,
    // width: 120,
    cursor: "pointer",
    "& img": {
      maxHeight: 60,
      objectFit: "contain",
    },
    "&:hover": {
      opacity: 0.5,
    },
  },
  sponsorList: {
    display: "flex",
    maxWidth: "calc(100% - 24px)",
    margin: "0 auto",
    gap: "3rem",
    flexWrap: "wrap",
    // gridTemplateColumns: "repeat(6, 1fr)",
    marginTop: "4rem",
    justifyContent: "center",
  },
});

interface SponsorProps {
  logoUrl: string;
  websiteUrl: string;
}

const Sponsor = (props: SponsorProps) => {
  const classes = useStyles();
  return (
    <div
      className={classes.sponsorLogoContainer}
      onClick={() => {
        window.open(props.websiteUrl);
      }}
    >
      <img src={props.logoUrl} alt="" />
    </div>
  );
};

const Sponsors = () => {
  const { sponsors, error, loading } = useFindAllSponsors();
  const classes = useStyles();
  // console.log(sponsors);
  return sponsors && sponsors.length > 0 ? (
    <div className={classes.sponsors}>
      <span className={classes.title}>Támogatók</span>
      <div className={classes.sponsorList}>
        {sponsors.map((sponsor) => (
          <Sponsor logoUrl={sponsor.logo.url} websiteUrl={sponsor.url} />
        ))}
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default Sponsors;
