import { useEffect, useState } from "react";
import { FAQ } from "../model/faq";
import { backendUrl, Endpoints } from "../utils/constants";
import useFetch from "./useFetch";

export const useFindAllFAQs = () => {
  const { response, error, loading } = useFetch(
    backendUrl + Endpoints.FAQs
    //   {
    //   headers: new Headers({
    //     Authorization: "Bearer " + process.env.REACT_APP_BEARER_TOKEN,
    //     "Access-Control-Allow-Methods": "GET",
    //   }),
    // }
  );
  const [FAQs, setFAQs] = useState<FAQ[]>();

  useEffect(() => {
    if (response) {
      setFAQs(
        response.data.map(
          (entry: any) =>
            ({
              id: entry.id,
              uid: entry.attributes.uid,
              question: entry.attributes.question,
              answer: entry.attributes.answer,
            } as FAQ)
        )
      );
    }
  }, [response]);

  return { FAQs, error, loading };
};
