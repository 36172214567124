import { useState, useEffect } from "react";

const useFetch = (url: any, options?: any) => {
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const doFetch = async () => {
      setLoading(true);
      fetch(url, options)
        .then((res) => {
          if (res.ok) {
            res
              .json()
              .then((json) => {
                if (!signal.aborted) {
                  setResponse(json);
                  setLoading(false);
                }
              })
              .catch((e) => {
                if (!signal.aborted) {
                  setError(e);
                  setLoading(false);
                }
              });
          } else {
            if (!signal.aborted) {
              setError(new Error("Something went wrong"));
              setLoading(false);
            }
          }
        })
        .catch((e) => {
          if (!signal.aborted) {
            setError(e);
            setLoading(false);
          }
        });
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, [url]);

  return { response, error, loading };
};
export default useFetch;
