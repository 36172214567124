import React from "react";
import { createUseStyles } from "react-jss";
import MenuBar from "../components/MenuBar";
import Page from "../components/Page";
import { useGetAbout } from "../hooks/useGetAbout";
import { themeColors } from "../theme";
import { PageRoutes } from "../utils/routes";
import ReactMarkdown from "react-markdown";

const useStyles = createUseStyles({
  title: {
    fontSize: 32,
    fontWeight: "600",
    alignSelf: "flex-start",
    flexGrow: 1,
    lineHeight: 2,
    color: themeColors.blue,
    "@media only screen and (max-width: 700px)": {
      marginTop: 40,
    },
  },
  aboutContainer: {
    maxWidth: 1280,
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    width: "100%",
    "@media only screen and (max-width: 700px)": {
      padding: 24,
    },
  },
  paragraph: {
    color: themeColors.blue,
    fontSize: 18,
    lineHeight: "26px",
    whiteSpace: "preWrap",
  },
});

const About = () => {
  const { about, loading, error } = useGetAbout();
  const classes = useStyles();
  return (
    <>
      <Page>
        <MenuBar selectedKey={PageRoutes.About} />
        <div className={classes.aboutContainer}>
          <span className={classes.title}>A fesztiválról</span>
          <ReactMarkdown className={classes.paragraph}>
            {about?.other ?? ""}
          </ReactMarkdown>
        </div>
      </Page>
    </>
  );
};

export default About;
