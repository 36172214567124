import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { useFindAllArticles } from "../hooks/useFindAllArticles";
import { themeColors } from "../theme";
import { useWindowSize } from "../utils/helper";
import { PageRoutes } from "../utils/routes";
import ArticleHighlight from "./ArticleHighlight";
import FloatingSocialIcons from "./FloatingSocialIcons";
import Spinner from "./Spinner";
import TextButton from "./TextButton";

const useStyles = createUseStyles({
  articleHighlightsContainer: {
    backgroundColor: themeColors.white,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflowX: "clip",
  },
  articleHighlights: {
    display: "flex",
    flexDirection: "column",
    width: 1280,
    alignSelf: "center",
    padding: "100px 0 32px 0",
  },
  articleHighlightsGroup: {
    display: "grid",
    width: 1100,
    alignSelf: "center",
    gridGap: "3rem",
    gridTemplateColumns: "650px 1fr",
  },
  articleHighlightsTop: {
    position: "absolute",
    top: -60,
    height: 90,
  },
  articleHighlightsBottom: {
    position: "absolute",
    bottom: -60,
    height: 90,
  },
  title: {
    fontSize: 32,
    fontWeight: "600",
    alignSelf: "flex-start",
    flexGrow: 1,
  },
  smallArticles: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
});

const ArticleHighlights = () => {
  const classes = useStyles();
  const { articles, loading, error } = useFindAllArticles();
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const smallArticles = articles?.slice(1, 3);

  return !articles || articles.length === 0 ? (
    <Spinner />
  ) : (
    <div className={classes.articleHighlightsContainer}>
      <img
        className={classes.articleHighlightsTop}
        src="/article-highlights-top.png"
        alt=""
      />
      <FloatingSocialIcons />
      {windowSize?.isMobile ? (
        <div style={{ marginTop: 60 }}>
          <span className={classes.title} style={{ padding: 24, fontSize: 24 }}>
            Bejegyzések
          </span>
          <div
            style={{
              padding: 24,
              gap: 40,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {articles.map((article) => (
              <ArticleHighlight article={article} />
            ))}
          </div>
        </div>
      ) : (
        <div className={classes.articleHighlights}>
          <div
            style={{
              marginBottom: 32,
              display: "flex",
              flexDirection: "row",
              width: 1100,
              alignSelf: "center",
            }}
          >
            <span className={classes.title}>Bejegyzések</span>
            <TextButton
              onClick={() => {
                navigate(PageRoutes.Articles);
              }}
            >
              Összes bejegyzés
            </TextButton>
          </div>
          <div className={classes.articleHighlightsGroup}>
            <ArticleHighlight article={articles[0]} />
            <div className={classes.smallArticles}>
              {smallArticles?.map((article) => (
                <ArticleHighlight article={article} isSmall />
              ))}
            </div>
          </div>
        </div>
      )}
      <img
        className={classes.articleHighlightsBottom}
        src="/article-highlights-bottom.png"
        alt=""
      />
    </div>
  );
};

export default ArticleHighlights;
