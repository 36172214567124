import "./App.css";
import { useFindAllAuthors } from "./hooks/useFindAllAuthors";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Article from "./pages/Article";
import { PageRoutes } from "./utils/routes";
import Articles from "./pages/Articles";
import Program from "./pages/Program";
import About from "./pages/About";
import Contact from "./pages/Contact";
import FAQ from "./pages/FAQ";
import ScrollToTop from "./components/ScrollToTop";
import Tickets from "./pages/Tickets";
import Rules from "./pages/Rules";
import Reviews from "./pages/Reviews";
import Registration from "./pages/Registration";
import Payment from "./pages/Payment";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path={PageRoutes.Home} element={<Home />} />
        <Route path={PageRoutes.Articles} element={<Articles />} />
        <Route
          path={`${PageRoutes.Articles}/:articleId`}
          element={<Article />}
        />
        <Route path={PageRoutes.Program} element={<Program />} />
        <Route path={PageRoutes.About} element={<About />} />
        <Route path={PageRoutes.Tickets} element={<Tickets />} />
        <Route path={PageRoutes.Rules} element={<Rules />} />
        <Route path={PageRoutes.Tickets} element={<Tickets />} />
        <Route path={PageRoutes.FAQ} element={<FAQ />} />
        <Route path={PageRoutes.Reviews} element={<Reviews />} />
        <Route path={PageRoutes.Registration} element={<Registration />} />
        <Route path={PageRoutes.Payment} element={<Payment />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
