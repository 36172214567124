import { Image } from "../model/image";

export const toImage = (image: any): Image => {
  return {
    name: image.data.attributes.name,
    width: image.data.attributes.width,
    height: image.data.attributes.height,
    url: image.data.attributes.url,
    id: image.data.id,
  };
};
