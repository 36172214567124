import { createUseStyles } from "react-jss";
import { themeColors } from "../theme";

const useStyles = createUseStyles({
  box: {
    display: "flex",
    flexDirection: "column",
    "& > span": {
      marginBottom: 16,
      color: themeColors.blue,
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 500,
    },
  },
  floating: {
    position: "absolute",
    top: 250,
    left: -300,
    display: "flex",
    flexDirection: "column",
    "& > span": {
      marginBottom: 16,
      color: themeColors.blue,
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 500,
    },
  },
  storeBadge: {
    maxWidth: 162,
    marginTop: 16,
    "&:hover": {
      opacity: 0.75,
      cursor: "pointer",
      transition: "all 0.2s",
    },
  },
});

interface FloatingActionBoxProps {
  variant: "default" | "floating";
}
const FloatingActionBox = (props: FloatingActionBoxProps) => {
  const classes = useStyles();
  return (
    <div
      className={props.variant === "floating" ? classes.floating : classes.box}
    >
      <span>Töltsd le az alkalmazást</span>
      <a
        target="__blank"
        href="https://apps.apple.com/ro/app/v%C3%A1lts-ir%C3%A1nyt/id1631479993?platform=iphone&fbclid=IwAR1eTt1aLYIBoHH8nvBLx-wrTMvGVX1wxcBGJyGMvbeXvHC27TANOdUdyVw"
      >
        <img
          className={classes.storeBadge}
          src="/App-Store-Badge.png"
          alt="toltsd-le-az-app-store-bol"
        ></img>
      </a>
      <a
        target="__blank"
        href="https://play.google.com/store/apps/details?id=ro.ike.valtsiranyt&gl=RO&fbclid=IwAR0uhlpoaVGQb9_8KooR-GwG0xJ8p-b9b6u8kw7qYvSTHivn_23_qVDh8Sc"
      >
        <img
          className={classes.storeBadge}
          alt="toltsd-le-a-google-play-bol"
          src="/Google-Play-Badge.png"
        ></img>
      </a>
    </div>
  );
};

export default FloatingActionBox;
