import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useFindAllDays } from "../hooks/useFindAllDays";
import { useFindAllLocations } from "../hooks/useFindAllLocations";
import { Day } from "../model/day";
import { themeColors } from "../theme";
import {
  convertDateToString,
  convertDateToStringShort,
  convertDayToString,
  useWindowSize,
} from "../utils/helper";
import Events from "./Events";

const useStyles = createUseStyles({
  calendar: {
    marginTop: 60,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    maxWidth: 1400,
    alignSelf: "center",
    width: "100%",
  },
  title: {
    fontSize: 32,
    fontWeight: "600",
    alignSelf: "flex-start",
    flexGrow: 1,
    lineHeight: 2,
    "@media only screen and (max-width: 700px)": {
      fontSize: 20,
      marginLeft: 24,
      marginBottom: 16,
    },
  },
  dayPicker: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: themeColors.white,
    padding: "16px 24px",
    margin: "0 8px",
    borderRadius: "32px 16px 22px 12px",
    alignItems: "center",
    "@media only screen and (max-width: 700px)": {
      justifyContent: "space-between",
      position: "sticky",
      top: 0,
      zIndex: 2,
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  dayPickerItem: {
    fontSize: 16,
    color: themeColors.blue,
    padding: "8px 12px",
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: themeColors.green,
      cursor: "pointer",
      textDecorationThickness: 2,
    },
  },
  dayPickerItemSelected: {
    fontSize: 16,
    backgroundColor: themeColors.green,
    color: themeColors.white,
    fontWeight: 600,
    padding: "8px 12px",
    borderRadius: "12px 16px 30px 16px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    "@media only screen and (max-width: 700px)": {
      flexDirection: "column",
    },
  },
});

interface DayPrehooked {
  date: Date;
  key: number;
  name: string;
}

interface DayProps extends DayPrehooked {
  date: Date;
  key: number;
  name: string;
  isSelected: boolean;
  days: Day[];
  onClick: () => void;
}

const DayPickerItem = (props: DayProps) => {
  const classes = useStyles();

  const { name, key, date, isSelected, onClick } = props;
  return (
    <div
      key={key}
      className={
        isSelected ? classes.dayPickerItemSelected : classes.dayPickerItem
      }
      onClick={onClick}
    >
      {name}
    </div>
  );
};

const Activities = () => {
  const classes = useStyles();
  const [selectedKey, setSelectedKey] = useState(1);
  const { days } = useFindAllDays();

  useEffect(() => {
    if (days && days.length) {
      setSelectedKey(days[0].id);
    }
  }, [days]);

  const windowSize = useWindowSize();

  return (
    <div className={classes.calendar}>
      <header className={classes.header}>
        <span className={classes.title}>Program</span>
        {days && days.length > 0 && (
          <div className={classes.dayPicker}>
            {days.map((day) => (
              <DayPickerItem
                date={day.date}
                name={
                  windowSize?.isMobile
                    ? convertDateToStringShort(day.date)
                    : convertDayToString(day.date)
                }
                key={day.id}
                isSelected={selectedKey === day.id}
                onClick={() => setSelectedKey(day.id)}
                days={days}
              />
            ))}
          </div>
        )}
        {windowSize?.isMobile && <Events selectedDayId={selectedKey} />}
      </header>
      {!windowSize?.isMobile && <Events selectedDayId={selectedKey} />}
    </div>
  );
};

export default Activities;
