import { useEffect } from "react";
import MenuBar from "../components/MenuBar";
import Page from "../components/Page";
import { PageRoutes } from "../utils/routes";

const Registration = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://f.momentumtools.io/static/js/dynamic_forms.js?v=4.0";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Page>
      <div style={{ paddingLeft: "15%", paddingRight: "15%" }}>
        <div id="momentumtools-form" data-form="v4_xtwnbrkdwbqy"></div>
        <link
          rel="stylesheet"
          href="https://f.momentumtools.io/static/css/dynamic_forms.css?v=4.0"
        />
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
        />
      </div>
    </Page>
  );
};

export default Registration;
