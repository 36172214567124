import { useEffect, useState } from "react";
import { Article } from "../model/article";
import { toImage } from "../transformers/transformToImage";
import { backendUrl, Endpoints } from "../utils/constants";
import useFetch from "./useFetch";

export const useFindArticle = (id?: string) => {
  const { response, error, loading } = useFetch(
    backendUrl + Endpoints.Articles + `/${id}?populate=*`
    // {
    //   headers: new Headers({
    //     Authorization: "Bearer " + process.env.REACT_APP_BEARER_TOKEN,
    //     "Access-Control-Allow-Methods": "GET",
    //   }),
    // }
  );
  const [article, setArticle] = useState<Article>();

  useEffect(() => {
    if (!error && response) {
      setArticle({
        id: response.data.id,
        uid: response.data.attributes.uid,
        title: response.data.attributes.title,
        subtitle: response.data.attributes.subtitle,
        content: response.data.attributes.content,
        date: new Date(response.data.attributes.createdAt),
        image: toImage(response.data.attributes.image),
        author: {
          id: response.data.attributes.author.data.id,
          uid: response.data.attributes.author.data.uid,
          name: response.data.attributes.author.data.attributes.name,
        },
      } as Article);
    }
  }, [error, response]);

  return { article, error, loading };
};
