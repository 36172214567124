import { useEffect, useState } from "react";
import MenuBar from "../components/MenuBar";
import Page from "../components/Page";
import { PageRoutes } from "../utils/routes";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import Button from "../components/Button";
import { Buffer } from "buffer";
import { json } from "stream/consumers";

const Payment = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const details = {
    orderNumber: params.get("id") as string,
    amount: params.get("amount") as string,
    currency: 946,
    returnUrl: "https://valtsiranyt.ro/",
    description: "TestDescription",
    language: "RO",
    pageView: "DESKTOP",
    email: "aron.dimeny2001@gmail.com",
    orderBundle: jsonStr({
      orderCreationDate: "2024-06-04",
      customerDetails: {
        email: "aron.dimeny2001@gmail.com",
        phone: "40740846339",
        deliveryInfo: {
          deliveryType: "comanda",
          country: 642,
          city: "oras_client",
          postAddress: "adresa_client",
        },
        billingInfo: {
          country: 642,
          city: "oras_client",
          postAddress: "adresa_client",
        },
      },
    }),
  };

  const formBodyStr = urlEncodeStr(details);

  const handleSubmit = async () => {
    fetch("https://valtsiranyt-fizetes-9ceee53cdda3.herokuapp.com", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBodyStr,
    })
      .then((response) => {
        if (response.redirected) {
          window.location.href = response.url;
        } else {
          return response.json().then((data) => {
            if (data.formUrl) {
              window.location.href = data.formUrl;
            } else {
              alert(data);
            }
          });
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <Page>
      <p>Fizetendő összeg: {params.get("amount")} </p>
      <p>Azonosító: {params.get("id")} </p>
      <p>!!EZ AZ OLDAL JELENLEG CSAK TESZTELÉS CÉLJÁBÓL LÉTEZIK!!</p>
      <Button onClick={handleSubmit}>Fizetés online</Button>
    </Page>
  );
};

const urlEncodeStr = (obj: object) => {
  const encodedStr = [];
  for (var property in obj) {
    var encodedKey = property;
    var encodedValue = obj[property as keyof typeof obj];
    encodedStr.push(encodedKey + "=" + encodedValue);
  }

  return encodedStr.join("&");
};

const jsonStr = (obj: object): string => {
  const encodedStr = [];
  for (var property in obj) {
    var encodedKey = property;
    var encodedValue = obj[property as keyof typeof obj];
    if (typeof obj[property as keyof typeof obj] === "object") {
      encodedStr.push(
        `"${encodedKey}"` + ":" + jsonStr(obj[property as keyof typeof obj])
      );
    } else {
      encodedStr.push(`"${encodedKey}"` + ":" + `"${encodedValue}"`);
    }
  }

  return "{" + encodedStr.join(",") + "}";
};

export default Payment;
