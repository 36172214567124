import { useEffect, useState } from "react";
import { Location } from "../model/location";
import { backendUrl, Endpoints } from "../utils/constants";
import useFetch from "./useFetch";

export const useFindAllLocations = () => {
  const { response, error, loading } = useFetch(
    backendUrl + Endpoints.Locations
    // {
    //   headers: new Headers({
    //     Authorization: "Bearer " + process.env.REACT_APP_BEARER_TOKEN,
    //     "Access-Control-Allow-Methods": "GET",
    //   }),
    // }
  );
  const [locations, setLocations] = useState<Location[]>();

  useEffect(() => {
    if (response) {
      const LocationsFromResponse: Location[] = response.data.map(
        (entry: any) =>
          ({
            id: entry.id,
            uid: entry.attributes.uid,
            title: entry.attributes.title,
          } as Location)
      );
      setLocations(LocationsFromResponse.sort((a, b) => a.id - b.id));
    }
  }, [response]);

  return { locations, error, loading };
};
