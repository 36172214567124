import { useCallback, useEffect, useState } from "react";
import { Activity } from "../model/activity";
import { Location } from "../model/location";
import EventBox from "./EventBox";

interface MobileEventsProps {
  locations: Location[];
  activities: Activity[];
  onClickEvent: (activity: Activity) => void;
}

const MobileEvents = (props: MobileEventsProps) => {
  const { locations, activities, onClickEvent } = props;

  const processLocations = useCallback((): Location[] => {
    const processedLocations: Location[] = [];
    locations.forEach((l) => {
      if (
        activities.filter(
          (activity) => activity.location.id === l.id && !activity.isCommon
        ).length > 0
      ) {
        processedLocations.push(l);
      }
    });
    return processedLocations;
  }, [locations, activities]);

  const [processedLocations, setProcessedLocations] = useState(
    processLocations()
  );

  useEffect(() => {
    setProcessedLocations(processLocations());
  }, [locations, processLocations]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: 24,
        paddingRight: 24,
        gap: 24,
      }}
    >
      {/* FOOD START */}
      <div>
        <span style={{ fontSize: 18, fontWeight: 500 }}>Etető</span>
        <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
          {activities
            .filter((activity) => activity.isCommon)
            .map((activity) => (
              <EventBox
                activity={activity}
                onClick={() => {
                  onClickEvent(activity);
                }}
              />
            ))}
        </div>
      </div>
      {/* FOOD END */}
      {processedLocations.map((location) => (
        <div>
          {activities.filter(
            (activity) =>
              activity.location.id === location.id && !activity.isCommon
          ).length > 0 && (
            <>
              <span style={{ fontSize: 18, fontWeight: 500 }}>
                {location.title}
              </span>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 8,
                }}
              >
                {activities
                  .filter(
                    (activity) =>
                      activity.location.id === location.id && !activity.isCommon
                  )
                  .map((activity) => (
                    <EventBox
                      activity={activity}
                      onClick={() => {
                        onClickEvent(activity);
                      }}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default MobileEvents;
