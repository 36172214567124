import { PulseLoader } from "react-spinners";
import { themeColors } from "../theme";

const Spinner = () => {
  return (
    <div
      style={{
        boxSizing: "border-box",
        display: "flex",
        margin: 60,
        justifyContent: "center",
      }}
    >
      <PulseLoader color={themeColors.orange} />
    </div>
  );
};

export default Spinner;
