import HyperModal, { IModalProps } from "react-hyper-modal";
import { createUseStyles } from "react-jss";
import { themeColors } from "../theme";
import { Calendar } from "react-feather";
import { Activity } from "../model/activity";
import {
  convertDateToStringShort,
  convertTimeToString,
  useWindowSize,
} from "../utils/helper";

const useStyles = createUseStyles({
  modalCloseIcon: {
    display: "none !important",
  },
  contentContainer: {
    width: "800px !important",
    boxShadow: "none !important",
    borderRadius: "24px !important",
    display: "flex",
    flexDirection: "column",
    height: "600px !important",
    "@media only screen and (max-width: 700px)": {
      height: "80% !important",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "& $p": {
      paddingLeft: 24,
      paddingRight: 24,
    },
    "& $h4": {
      paddingLeft: 24,
      paddingRight: 24,
    },
    "@media only screen and (min-width: 700px)": {
      "& $p": {
        marginLeft: 420,
        marginRight: 40,
        color: themeColors.blue,
      },
      "& $h4": {
        marginLeft: 420,
        marginRight: 40,
        fontSize: 14,
        color: themeColors.blue,
        marginTop: 24,
      },
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: themeColors.tintYellow,
    justifyContent: "flex-end",
    paddingBottom: 60,
  },
  description: {
    "& $p": {
      lineHeight: 1.5,
    },
  },
  title: {
    fontSize: 22,
    marginBottom: 8,
    fontWeight: 600,
    marginTop: 120,
    "@media only screen and (max-width: 700px)": {
      marginTop: 32,
    },
  },
  titleUnderline: {
    width: 180,
    marginLeft: 420,
    "@media only screen and (max-width: 700px)": {
      marginLeft: 24,
    },
  },
  closeButton: {
    border: "none",
    background: "none",
    padding: 0,
    position: "absolute",
    cursor: "pointer",
    height: 48,
    width: 48,
    top: 24,
    right: 32,
    "&:hover": {
      opacity: 0.7,
      transition: "0.2s all",
    },
  },
  timeCard: {
    marginLeft: 420,
    padding: "16px 20px",
    backgroundColor: themeColors.blue,
    display: "flex",
    flexDirection: "row",
    borderRadius: "24px 32px 24px 24px",
    width: "fit-content",
    alignItems: "center",
    gap: 16,
    marginTop: -32,
    "&>div": {
      display: "flex",
      flexDirection: "column",
    },
    "& span:nth-of-type(1)": {
      color: themeColors.white,
      fontSize: 14,
      lineHeight: 1.25,
    },
    "& span:nth-of-type(2)": {
      color: themeColors.white,
      fontSize: 14,
      fontWeight: 600,
    },
    "@media only screen and (max-width: 700px)": {
      marginLeft: 24,
    },
  },
  webImage: {
    width: 360,
    height: 360,
    position: "absolute",
    objectFit: "cover",
    top: 80,
    borderRadius: "0 32px 80px 0",
    "@media only screen and (max-width: 700px)": {
      display: "none",
    },
  },
});

interface TimeCardProps {
  activity: Activity;
}

const TimeCard = (props: TimeCardProps) => {
  const { activity } = props;
  const classes = useStyles();
  return (
    <div className={classes.timeCard}>
      <Calendar color={themeColors.white} />
      <div>
        <span>{`${convertDateToStringShort(
          activity.day.date
        )} ${convertTimeToString(activity.startTime)} - ${convertTimeToString(
          activity.endTime
        )}`}</span>
        <span>
          {activity.subtitle
            ? `${activity.location.title} ${activity.subtitle}`
            : activity.location.title}
        </span>
      </div>
    </div>
  );
};

interface VIModalProps extends IModalProps {
  activity?: Activity;
}

const VIModal = (props: VIModalProps) => {
  const { activity, ...otherProps } = props;
  const classes = useStyles();
  const windowSize = useWindowSize();

  return (
    <HyperModal
      {...otherProps}
      classes={{
        closeIconClassName: classes.modalCloseIcon,
        contentClassName: classes.contentContainer,
      }}
    >
      {activity && (
        <div className={classes.content}>
          {windowSize?.isMobile ? (
            <img
              style={{ height: 240, objectFit: "cover" }}
              alt=""
              src={windowSize?.isMobile ? activity.image.url : ""}
            />
          ) : (
            <img
              className={classes.webImage}
              alt=""
              src={windowSize?.isMobile ? "" : activity.image.url}
            />
          )}

          <button className={classes.closeButton} onClick={props.requestClose}>
            <img src="./close-circle.png" height={48} width={48} alt="" />
          </button>
          <div className={classes.header}>
            <p className={classes.title}>{activity.title}</p>
            <img
              src="/title-underline.png"
              className={classes.titleUnderline}
              alt=""
            />
          </div>
          <TimeCard activity={activity} />
          <div className={classes.description}>
            <h4>{activity.category.name}</h4>
            <p>{activity.description}</p>
          </div>
        </div>
      )}
    </HyperModal>
  );
};

export default VIModal;
