import reviews from "../reviews.json";
import * as _ from "lodash";
import { themeColors } from "../theme";
import { useFindAllActivities } from "../hooks/useFindAllActivities";
import { useFindAllConstantActivity } from "../hooks/useFindAllConstantActivities";
import { useEffect, useState } from "react";
import { convertDayToString } from "../utils/helper";

const Reviews = () => {
  const { activities } = useFindAllActivities();
  const { constantActivities } = useFindAllConstantActivity();

  const [extendedActivities, setExtendedActivities] = useState<any>();
  const [extendedConstantActivities, setExtendedConstantActivities] =
    useState<any>();

  useEffect(() => {
    setExtendedActivities(activities?.map((activity) => ({ ...activity })));
  }, [activities]);

  useEffect(() => {
    setExtendedConstantActivities(
      constantActivities?.map((activity) => ({ ...activity }))
    );
  }, [constantActivities]);

  const parsedReviews = reviews.data.map((review: any) => {
    const title =
      review.attributes.activity.data !== null
        ? review.attributes.activity.data.attributes.title
        : review.attributes.constant_activity.data !== null
        ? review.attributes.constant_activity.data.attributes.title
        : "";

    return {
      activity: review.attributes.activity,
      constantActivity: review.attributes.constant_activity,
      rating: review.attributes.rating,
      description: review.attributes.description,
      title: title,
    };
  });

  const activityReviews = parsedReviews
    .filter((review) => review.activity.data !== null)
    .sort((a, b) => {
      if (a.activity.data.id > b.activity.data.id) return 1;
      else return -1;
    });

  const constantActivityReviews = parsedReviews
    .filter((review) => review.constantActivity.data !== null)
    .sort((a, b) => {
      if (a.constantActivity.data.id > b.constantActivity.data.id) return 1;
      else return -1;
    });

  //   console.log(_.groupBy(activityReviews, "title"));
  //   console.log(constantActivityReviews);

  const groupedActivityReviews = _.groupBy(activityReviews, "title");
  const groupedConstantActivityReviews = _.groupBy(
    constantActivityReviews,
    "title"
  );

  //   console.log(extendedActivities);
  return (
    <>
      {extendedActivities && extendedActivities.length !== 0 && (
        <div
          style={{
            display: "flex",
            width: 1000,
            flexDirection: "column",
            gap: 16,
            margin: "auto",
            marginTop: 100,
            marginBottom: 100,
          }}
        >
          <span
            style={{ color: themeColors.blue, fontWeight: 500, fontSize: 24 }}
          >
            Visszajelzések, Válts irányt! 2022
          </span>
          {Object.keys(groupedActivityReviews).map((key) => (
            <>
              <span
                style={{
                  marginTop: 40,
                  fontWeight: 500,
                  color: themeColors.blue,
                }}
              >
                {
                  extendedActivities.find(
                    (activity: any) => activity.title === key
                  ).location.title
                }
                {", "}
                {`
                  ${convertDayToString(
                    extendedActivities.find(
                      (activity: any) => activity.title === key
                    ).day.date
                  )}`}
                {" // "}
                {key} -{" "}
                {Math.floor(
                  _.mean(
                    groupedActivityReviews[key].map((review) => review.rating)
                  ) * 100
                ) / 100}
              </span>
              {groupedActivityReviews[key].map((review) => (
                <span
                  style={{
                    color: review.rating === 1 ? "#FB4027" : themeColors.blue,
                    opacity: 0.75,
                  }}
                >
                  Pont: {review.rating}{" "}
                  {review.description ? (
                    <span>→ {review.description}</span>
                  ) : (
                    ""
                  )}
                </span>
              ))}
            </>
          ))}
          {Object.keys(groupedConstantActivityReviews).map((key) => (
            <>
              <span
                style={{
                  marginTop: 40,
                  fontWeight: 500,
                  color: themeColors.blue,
                }}
              >
                {key} -{" "}
                {Math.floor(
                  _.mean(
                    groupedConstantActivityReviews[key].map(
                      (review) => review.rating
                    )
                  ) * 100
                ) / 100}
              </span>
              {groupedConstantActivityReviews[key].map((review) => (
                <span
                  style={{
                    color: review.rating === 1 ? "#FB4027" : themeColors.blue,
                    opacity: 0.75,
                  }}
                >
                  Pont: {review.rating}{" "}
                  {review.description ? (
                    <span>→ {review.description}</span>
                  ) : (
                    ""
                  )}
                </span>
              ))}
            </>
          ))}
        </div>
      )}
    </>
  );
};

export default Reviews;
