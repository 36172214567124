import React from "react";
import Activities from "../components/Activities";
import ConstantActivities from "../components/ConstantActivities";
import Events from "../components/Events";
import MenuBar from "../components/MenuBar";
import Page from "../components/Page";
import { PageRoutes } from "../utils/routes";

const Program = () => {
  return (
    <>
      <Page>
        <MenuBar selectedKey={PageRoutes.Program} />
        <ConstantActivities />
        <Activities />
      </Page>
    </>
  );
};

export default Program;
