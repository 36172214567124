import { useEffect, useState } from "react";
import { About } from "../model/about";
import { Map } from "../model/map";
import { toImage } from "../transformers/transformToImage";
import { backendUrl, Endpoints } from "../utils/constants";
import useFetch from "./useFetch";

export const useGetMap = () => {
  const { response, error, loading } = useFetch(
    backendUrl + Endpoints.Map + "?populate=*"
    // {
    //   headers: new Headers({
    //     Authorization: "Bearer " + process.env.REACT_APP_BEARER_TOKEN,
    //     "Access-Control-Allow-Methods": "GET",
    //   }),
    // }
  );
  const [map, setMap] = useState<Map>();

  // console.log(map);
  useEffect(() => {
    if (!error && response) {
      setMap({
        uid: response.data.attributes.uid,
        verticalImage: toImage(response.data.attributes.verticalImage),
        horizontalImage: toImage(response.data.attributes.horizontalImage),
        backgroundColor: response.data.attributes.backgroundColor,
      } as Map);
    }
  }, [error, response]);

  return { map, error, loading };
};
