import React from "react";
import MenuBar from "../components/MenuBar";
import Map from "../components/Map";
import Page from "../components/Page";
import Sponsors from "../components/Sponsors";
import { PageRoutes } from "../utils/routes";
import { createUseStyles } from "react-jss";
import { themeColors } from "../theme";
import ArticleHighlights from "../components/ArticleHighlights";
import Activities from "../components/Activities";
import FloatingSocialIcons from "../components/FloatingSocialIcons";
import { useFindAllDays } from "../hooks/useFindAllDays";
import { useWindowSize } from "../utils/helper";
import ConstantActivities from "../components/ConstantActivities";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

const useStyles = createUseStyles({
  home: {
    backgroundColor: themeColors.blue,
    display: "flex",
    flexDirection: "column",
    paddingBottom: 140,
    paddingTop: 40,
  },
  header: {
    width: 600,
    alignSelf: "center",
    "@media only screen and (max-width: 700px)": {
      width: 400,
      maxWidth: "90%",
    },
  },
  leftShape: {
    position: "absolute",
    left: 0,
    top: 0,
    maxHeight: 500,
    zIndex: 2,
  },
  rightShape: {
    position: "absolute",
    right: -100,
    top: 0,
    maxHeight: 500,
    zIndex: 2,
  },
  phantom: { "@media only screen and (max-width: 700px)": { marginTop: 0 } },
});

const HeaderContent = () => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  return (
    <>
      <img
        className={classes.leftShape}
        src="./left-shape-2024.png"
        alt=""
        style={{ display: windowSize?.isMobile ? "none" : "flex" }}
      />
      <img
        className={classes.rightShape}
        src="./right-shape-2024.png"
        alt=""
        style={{ display: windowSize?.isMobile ? "none" : "flex" }}
      />
      <div className={classes.home}>
        <img
          className={classes.header}
          src="./valts-iranyt-header-2024.png"
          alt=""
        />
        {/* <Button
          onClick={() => {
            navigate("/regisztracio");
          }}
        >
          Regisztrálok
        </Button> */}
      </div>
    </>
  );
};

const Home = () => {
  const classes = useStyles();
  return (
    <>
      <Page>
        <MenuBar selectedKey={PageRoutes.Home} isDark />
        <HeaderContent />
        <ArticleHighlights />
        <div style={{ marginBottom: 40 }} />
        <Activities />
        <div className={classes.phantom} />
        <ConstantActivities />
        <Map />
        <Sponsors />
      </Page>
    </>
  );
};

export default Home;
