import React from "react";
import MenuBar from "../components/MenuBar";
import Page from "../components/Page";
import { PageRoutes } from "../utils/routes";
import FaqComponent from "react-faq-component";
import { createUseStyles } from "react-jss";
import { themeColors } from "../theme";
import { useFindAllFAQs } from "../hooks/useFindAllFAQs";

const useStyles = createUseStyles({
  faqContainer: {
    maxWidth: 1280,
    display: "flex",
    alignSelf: "center",
    width: 1280,
    "& .faq-row-wrapper": {
      width: "100%",
      backgroundColor: "transparent",
    },
    "& .faq-title": {
      marginBottom: 32,
      borderBottom: "none",
      color: themeColors.blue,
      "& h2": {
        color: "inherit",
      },
    },
    "& .row-title-text": {
      fontWeight: 500,
      color: themeColors.blue,
    },
    "& .row-content-text": {
      color: `${themeColors.blue} !important`,
    },
  },
});

const FAQ = () => {
  const classes = useStyles();
  const { FAQs, error, loading } = useFindAllFAQs();
  const data = {
    title: "Gyakori kérdések",
    rows: FAQs?.map((faq) => ({ title: faq.question, content: faq.answer })),
  };

  return (
    <>
      <Page>
        <MenuBar selectedKey={PageRoutes.FAQ} />
        <div className={classes.faqContainer}>
          <FaqComponent data={data} />
        </div>
      </Page>
    </>
  );
};

export default FAQ;
