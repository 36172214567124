import { createUseStyles } from "react-jss";
import { useFindAllConstantActivity } from "../hooks/useFindAllConstantActivities";
import { ConstantActivity } from "../model/constant-activity";
import { themeColors } from "../theme";

const useStyles = createUseStyles({
  title: {
    fontSize: 20,
    fontWeight: "600",
    alignSelf: "flex-start",
    flexGrow: 1,
    color: themeColors.blue,
    "@media only screen and (max-width: 700px)": {
      fontSize: 20,
      marginTop: 60,
    },
  },
  constantContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 32,
    "@media only screen and (max-width: 800px)": {
      flexDirection: "column",
    },
  },
});

const ConstantActivities = () => {
  const classes = useStyles();
  const { constantActivities } = useFindAllConstantActivity();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 32,
        maxWidth: 1400,
        alignSelf: "center",
        marginLeft: 24,
        marginRight: 24,
        marginTop: 80,
      }}
    >
      <span className={classes.title}>Állandó programok</span>

      <div className={classes.constantContainer}>
        {constantActivities?.length === 3 ? (
          <>
            <ConstantActivityVisual constantActivity={constantActivities[2]} />
            <ConstantActivityVisual constantActivity={constantActivities[0]} />
            <ConstantActivityVisual constantActivity={constantActivities[1]} />
          </>
        ) : (
          constantActivities?.map((ca) => (
            <ConstantActivityVisual constantActivity={ca} />
          ))
        )}
      </div>
    </div>
  );
};

interface ConstantActivityProps {
  constantActivity: ConstantActivity;
}

const ConstantActivityVisual = (props: ConstantActivityProps) => {
  const { constantActivity: ca } = props;
  const descriptions = ca.description.split("FONTOS INFORMÁCIÓK! ");
  // console.log(x);
  return (
    <div
      key={ca.id}
      style={{
        padding: 24,
        backgroundColor: themeColors.white,
        display: "flex",
        flexDirection: "column",
        gap: 16,
        borderRadius: "16px 24px 32px 40px",
      }}
    >
      <span style={{ fontSize: 16, fontWeight: 500, color: themeColors.blue }}>
        {ca.title}
      </span>
      <span style={{ lineHeight: 1.4, color: themeColors.blue, opacity: 0.8 }}>
        {descriptions[0]}
      </span>
      {/* <span
        style={{
          fontSize: 14,
          lineHeight: 1.4,
          opacity: 0.8,
          marginBottom: -8,
          marginTop: 16,
          fontWeight: 500,
          color: themeColors.blue,
          background: themeColors.tintGreen,
          width: "max-content",
          padding: "0 8px",
          borderRadius: "12px 8px 16px 8px",
        }}
      >
        Fontos információk:
      </span>
      <span
        style={{
          fontSize: 14,
          lineHeight: 1.4,
          color: themeColors.blue,
          opacity: 0.8,
        }}
      >
        {descriptions[1]}
      </span> */}
    </div>
  );
};

export default ConstantActivities;
