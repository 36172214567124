import { useEffect, useState } from "react";
import { Sponsor } from "../model/sponsor";
import { toImage } from "../transformers/transformToImage";
import { backendUrl, Endpoints } from "../utils/constants";
import useFetch from "./useFetch";

export const useFindAllSponsors = () => {
  const { response, error, loading } = useFetch(
    backendUrl + Endpoints.Sponsors + "?populate=*"
    // {
    //   headers: new Headers({
    //     Authorization: "Bearer " + process.env.REACT_APP_BEARER_TOKEN,
    //     "Access-Control-Allow-Methods": "GET",
    //   }),
    // }
  );
  const [sponsors, setSponsors] = useState<Sponsor[]>();

  useEffect(() => {
    if (response) {
      const sponsorsFromResponse: Sponsor[] = response.data.map(
        (entry: any) =>
          ({
            id: entry.id,
            uid: entry.attributes.uid,
            url: entry.attributes.url,
            name: entry.attributes.name,
            logo: toImage(entry.attributes.logo),
          } as Sponsor)
      );
      setSponsors(sponsorsFromResponse);
    }
  }, [response]);

  return { sponsors, error, loading };
};
