import React from "react";
import { createUseStyles } from "react-jss";
import { themeColors } from "../theme";
import Footer from "./Footer";

const useStyles = createUseStyles({
  page: {
    display: "flex",
    backgroundColor: themeColors.background,
    flex: 1,
    flexDirection: "column",
    width: "100vw",
    minHeight: "70vh",
    position: "relative",
    overflowX: "clip",
  },
});

interface PageProps {
  children?: React.ReactNode;
  variant?: "default" | "pattern";
}

const Page: React.FC<PageProps> = (props) => {
  const { children, variant } = props;
  const classes = useStyles();
  return (
    <>
      <div
        className={classes.page}
        style={{
          backgroundImage:
            variant === "pattern" ? "url(/bg-cross.png)" : "none",
        }}
      >
        {children}
      </div>
      <Footer />
    </>
  );
};

export default Page;
