import React from "react";
import { createUseStyles } from "react-jss";
import { NavLink, Outlet } from "react-router-dom";
import ArticleHighlight from "../components/ArticleHighlight";
import MenuBar from "../components/MenuBar";
import Page from "../components/Page";
import { useFindAllArticles } from "../hooks/useFindAllArticles";
import { themeColors } from "../theme";
import { PageRoutes } from "../utils/routes";

const useStyles = createUseStyles({
  title: {
    fontSize: 32,
    fontWeight: "600",
    alignSelf: "flex-start",
    flexGrow: 1,
    color: themeColors.blue,
    "@media only screen and (max-width: 700px)": {
      fontSize: 20,
      marginTop: 40,
      marginBottom: -24,
    },
  },
});

const Articles = () => {
  const { articles } = useFindAllArticles();
  const classes = useStyles();
  return (
    <>
      <Page>
        <MenuBar selectedKey={PageRoutes.Articles} />
        {articles && articles.length > 0 && (
          <div
            style={{
              maxWidth: "100%",
              padding: 24,
              width: 800,
              display: "flex",
              flexDirection: "column",
              gap: 60,
              alignSelf: "center",
            }}
          >
            <span className={classes.title}>Bejegyzések</span>
            {articles.map((article) => (
              <NavLink
                to={`/bejegyzesek/${article.id}`}
                style={{ textDecoration: "none" }}
              >
                <ArticleHighlight article={article} />
              </NavLink>
            ))}
          </div>
        )}
        {/* <Outlet /> */}
      </Page>
    </>
  );
};

export default Articles;
