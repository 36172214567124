export const themeColors = {
  background: "#FFF9EC",
  white: "#FFFFFF",
  blue: "#14183D",
  green: "#36C0A6",
  orange: "#FF4F2C",
  mediumOrange: "#FFA795",
  border: "#E8E3DB",
  tintYellow: "#FFEDC7",
  yellow: "#F2C94C",
  tintOrange: "#FFE8D9",
  tintGreen: "#E8F5DE",
  tintPurple: "#F5E8FF",
  tintBlue: "#E8EDFF",
};
