import { useState } from "react";
import Lightbox from "react-image-lightbox";
import { createUseStyles } from "react-jss";
import { themeColors } from "../theme";
import "react-image-lightbox/style.css";
import { useGetMap } from "../hooks/useGetMap";
import { useWindowSize } from "../utils/helper";

const useStyles = createUseStyles({
  mapContainer: {
    display: "flex",
    flexDirection: "column",
    margin: 160,
    "@media only screen and (max-width: 700px)": {
      margin: 24,
      marginTop: 120,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
    alignSelf: "center",
    textAlign: "center",
  },
  map: {
    width: "100%",
    maxWidth: 1100,
    background: themeColors.blue,
    alignSelf: "center",
    marginTop: 32,
    cursor: "pointer",
    "&:hover": {
      filter: "brightness(0.75)",
    },
    transition: "all 0.2s ease-in",
    borderRadius: "24px 32px 20px 30px",
  },
});

const Map = () => {
  const classes = useStyles();
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const { map } = useGetMap();
  const windowSize = useWindowSize();
  return (
    <div className={classes.mapContainer}>
      <span className={classes.title}>Fesztiváltérkép</span>
      {map && (
        <>
          <img
            alt="map"
            className={classes.map}
            src={
              windowSize?.isMobile
                ? map.verticalImage.url
                : map.horizontalImage.url
            }
            onClick={() => {
              setIsLightboxOpen(true);
            }}
          />
          {isLightboxOpen && (
            <Lightbox
              mainSrc={
                windowSize?.isMobile
                  ? map.verticalImage.url
                  : map.horizontalImage.url
              }
              onCloseRequest={() => {
                setIsLightboxOpen(false);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Map;
