import { createUseStyles } from "react-jss";
import { themeColors } from "../theme";
import EventBox from "./EventBox";
import { useEffect, useState } from "react";
import VIModal from "./VIModal";
import { useFindAllLocations } from "../hooks/useFindAllLocations";
import { useFindAllActivities } from "../hooks/useFindAllActivities";
import { Activity } from "../model/activity";
import { range, useWindowSize } from "../utils/helper";
import MobileEvents from "./MobileEvents";
import Spinner from "./Spinner";

const useStyles = createUseStyles({
  events: {
    marginTop: 64,
    display: "flex",
    flexDirection: "row",
    position: "relative",
    width: "100%",
    alignSelf: "center",
    minWidth: 1100,
    marginLeft: 24,
    "& .hm_hyperModalWrapper": {
      zIndex: 3,
    },
    "@media only screen and (max-width: 700px)": {
      minWidth: 200,
      marginLeft: 0,
      marginTop: 32,
    },
  },
  hoursLeft: {
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
    marginTop: 38,
    paddingLeft: 8,
    "&>div": {
      height: 94,
      fontSize: 12,
      fontWeight: 500,
    },
  },
  hoursRight: {
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
    marginTop: 38,
    paddingRight: 20,
    "&>div": {
      height: 94,
      fontSize: 12,
      fontWeight: 500,
    },
  },
  calendar: {
    display: "flex",
    flexGrow: 1,
    margin: "0 24px",
    marginBottom: 60,
    "@media only screen and (max-width: 700px)": {
      overflowX: "auto",
      margin: "0 8px",
    },
  },
  eventBoxContainer: {
    height: 94,
    border: "0.5px solid",
    borderColor: themeColors.border,
    position: "relative",
  },
  eventLocationTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "32px",
    marginLeft: 12,
    marginBottom: 12,
  },
  locationColumn: {
    width: "100%",
    transition: "all 150ms",
    "&:hover": { background: "#00000008" },
  },
  emptyMessage: {
    width: "100%",
    textAlign: "center",
    fontSize: 16,
    marginRight: 8,
    borderRadius: 16,
    padding: 16,
    marginBottom: 120,
    backgroundColor: "#00000010",
    "@media only screen and (max-width: 700px)": {
      margin: "0 24px",
      marginTop: 0,
      marginBottom: 60,
    },
  },
});

interface LocationColumnProps {
  location: string;
  onClickEvent: (activity: Activity) => void;
  activities: Activity[];
  hours: number[];
}

const LocationColumn = (props: LocationColumnProps) => {
  const { location, activities, hours, onClickEvent } = props;

  const classes = useStyles();
  return (
    <div className={classes.locationColumn}>
      <div style={{ marginBottom: 12, position: "sticky", top: 0, zIndex: 2 }}>
        <span className={classes.eventLocationTitle}>{location}</span>
      </div>
      {hours.map((hour) => (
        <div className={classes.eventBoxContainer}>
          {activities
            .filter((activity) => activity.startTime.hour === hour)
            .map((activity) => (
              <EventBox
                onClick={() => onClickEvent(activity)}
                activity={activity}
              />
            ))}
        </div>
      ))}
    </div>
  );
};

interface EventsProps {
  selectedDayId: number;
}

const Events = (props: EventsProps) => {
  const { selectedDayId } = props;
  const classes = useStyles();
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const { locations, error } = useFindAllLocations();
  const { activities, loading } = useFindAllActivities(selectedDayId);
  const [selectedActivity, setSelectedActivity] = useState<Activity>();
  const [firstAt, setFirstAt] = useState(7);
  const [lastAt, setLastAt] = useState(24);

  const [hours, setHours] = useState([
    7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
  ]);

  useEffect(() => {
    setHours(
      range(
        firstAt >= 8 ? firstAt - 1 : firstAt,
        lastAt <= 24 ? lastAt : lastAt
      )
    );
  }, [firstAt, lastAt]);

  useEffect(() => {
    if (activities && activities.length > 0) {
      const hours = activities.map((activity) => ({
        start: activity.startTime.hour,
        end: activity.endTime.hour,
      }));
      const startHours = hours.map((hour) => hour.start);
      if (startHours.length > 0) {
        setFirstAt(Math.min(...startHours));
        const endHours = hours.map((hour) => hour.end);
        // console.log(endHours);
        setLastAt(Math.max(...endHours));
      }
    }
  }, [activities]);
  const windowSize = useWindowSize();

  // console.log(locations);
  return (
    <div className={classes.events}>
      {loading ? (
        <div style={{ width: "100%", marginBottom: 40 }}>
          <Spinner />
        </div>
      ) : (
        locations &&
        activities &&
        (activities.length === 0 ? (
          <span className={classes.emptyMessage}>
            A programrács folyamatosan frissül
          </span>
        ) : (
          <>
            {windowSize?.isMobile ? (
              <>
                <div style={{ flex: 1 }}>
                  <MobileEvents
                    activities={activities}
                    locations={locations}
                    onClickEvent={(activity: Activity) => {
                      setIsEventModalOpen(true);
                      setSelectedActivity(activity);
                    }}
                  />
                </div>
                <VIModal
                  isOpen={isEventModalOpen}
                  afterClose={() => setIsEventModalOpen(false)}
                  requestClose={() => {
                    setIsEventModalOpen(false);
                    setSelectedActivity(undefined);
                  }}
                  activity={selectedActivity}
                  stackableIndex={3}
                />
              </>
            ) : (
              <>
                <div className={classes.hoursLeft}>
                  {hours.map((hour) => (
                    <div>{`${hour >= 24 ? hour - 24 : hour}:00`}</div>
                  ))}
                </div>
                <div className={classes.calendar}>
                  {locations.map((location) => (
                    <LocationColumn
                      location={location.title}
                      onClickEvent={(activity: Activity) => {
                        setIsEventModalOpen(true);
                        setSelectedActivity(activity);
                      }}
                      activities={activities?.filter(
                        (activity) => activity.location.id === location.id ?? []
                      )}
                      hours={hours}
                    />
                  ))}
                </div>
                <div className={classes.hoursRight}>
                  {hours.map((hour) => (
                    <div>{`${hour >= 24 ? hour - 24 : hour}:00`}</div>
                  ))}
                </div>
                <VIModal
                  isOpen={isEventModalOpen}
                  afterClose={() => setIsEventModalOpen(false)}
                  requestClose={() => {
                    setIsEventModalOpen(false);
                    setSelectedActivity(undefined);
                  }}
                  activity={selectedActivity}
                  stackableIndex={3}
                />
              </>
            )}
          </>
        ))
      )}
    </div>
  );
};

export default Events;
