import React from "react";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import FloatingActionBox from "../components/FloatingApplicationBox";
import MenuBar from "../components/MenuBar";
import Page from "../components/Page";
import { themeColors } from "../theme";
import { PageRoutes } from "../utils/routes";
import { Article as ArticleModel } from "../model/article";
import { useFindArticle } from "../hooks/useFindArticle";
import { convertDateToString } from "../utils/helper";
import ReactMarkdown from "react-markdown";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";

const useStyles = createUseStyles({
  title: {
    color: themeColors.blue,
    fontSize: 36,
    lineHeight: "40px",
    fontWeight: 600,
    "@media only screen and (max-width: 700px)": {
      marginTop: 80,
      fontSize: 24,
      marginBottom: -16,
    },
  },
  caption: {
    color: themeColors.blue,
    opacity: 0.6,
    marginTop: 16,
    fontSize: 14,
    lineHeight: 1,
  },
  articleContainer: {
    display: "flex",
    width: 700,
    maxWidth: "90%",
    alignSelf: "center",
    flexDirection: "column",
    position: "relative",
    marginBottom: 120,
    "@media only screen and (max-width: 700px)": {
      maxWidth: "100%",
      padding: 24,
    },
  },
  paragraph: {
    color: themeColors.blue,
    fontSize: 18,
    lineHeight: "26px",
  },
  thumbnail: {
    width: 700,
    maxWidth: "90%",
    margin: "24px 0",
    borderRadius: 12,
  },
});

const Article = () => {
  let params = useParams();
  const { article, loading, error } = useFindArticle(params?.articleId);
  // console.log(article, error);
  const classes = useStyles();
  return (
    <>
      <Page variant="pattern">
        <ScrollToTop />
        <MenuBar selectedKey={PageRoutes.Articles} />
        {/* <section className="content">Article {params.articleId}</section> */}
        {!article ? null : (
          <div className={classes.articleContainer}>
            <Helmet>
              <title>{article.title}</title>
              <meta charSet="utf-8" />
              <meta
                name="viewport"
                content="initial-scale=1.0, width=device-width"
              />
              <meta name="title" key="title" content={article.title} />
              <meta
                property="og:title"
                key="og:title"
                content={article.title}
              />
              <meta
                property="og:image"
                key="og:image"
                content={article.image.url}
              />
            </Helmet>
            <span className={classes.title}>{article?.title}</span>
            <span className={classes.caption}>
              {`${article?.author.name} / ${convertDateToString(
                article?.date ?? new Date()
              )}`}
            </span>
            <img
              className={classes.thumbnail}
              src={article?.image.url}
              alt=""
            ></img>
            <p className={classes.paragraph}>
              <ReactMarkdown>{article?.content ?? ""}</ReactMarkdown>
            </p>
            <FloatingActionBox variant="floating" />
          </div>
        )}
        {error && (
          <span style={{ textAlign: "center", marginTop: 120 }}>
            A keresett cikk nem található.
          </span>
        )}
      </Page>
    </>
  );
};

export default Article;
