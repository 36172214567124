import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { themeColors } from "../theme";
import { assignClasses } from "../utils/helper";
import { PageRoutes } from "../utils/routes";
import FloatingSocialIcons from "./FloatingSocialIcons";

const useStyles = createUseStyles({
  menubar: {
    zIndex: 1,
    height: 120,
    backgroundColor: themeColors.white,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    transition: "all 0.2s ease-in",
  },
  menubarDark: { backgroundColor: themeColors.blue },
  menuItemsContainer: {
    display: "flex",
    alignItems: "center",
    "& a": {
      marginLeft: 32,
      fontSize: 14,
      color: themeColors.blue,
      textDecoration: "none",
      opacity: 0.7,
    },
    "& a:hover": {
      textDecoration: "underline",
    },
    transition: "all 0.2s ease-in",
    "@media only screen and (max-width: 700px)": {
      "& a": {
        marginLeft: 24,
        fontSize: 14,
      },
      flexWrap: "wrap",
      maxWidth: "100%",
      paddingTop: 16,
      paddingBottom: 16,
    },
  },
  menuItemsContainerDark: { "& a": { color: themeColors.white } },
  selectedMenuItem: {
    opacity: "1 !important",
    fontWeight: 600,
  },
  wave: {
    marginTop: -50,
    zIndex: 0,
    overflowX: "hidden",
  },
});

interface MenuBarProps {
  selectedKey: string;
  isDark?: boolean;
}

const MenuBar = (props: MenuBarProps) => {
  const { selectedKey, isDark } = props;
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    switch (selectedKey) {
      case PageRoutes.Home:
        setSelectedIndex(0);
        break;
      case PageRoutes.Articles:
        setSelectedIndex(1);
        break;
      case PageRoutes.Program:
        setSelectedIndex(2);
        break;
      case PageRoutes.Tickets:
        setSelectedIndex(3);
        break;
      case PageRoutes.About:
        setSelectedIndex(4);
        break;
      case PageRoutes.Rules:
        setSelectedIndex(5);
        break;
      default:
        break;
    }
  }, [selectedKey]);
  return (
    <>
      <div
        className={assignClasses(classes.menubar, classes.menubarDark, isDark)}
      >
        <div
          className={assignClasses(
            classes.menuItemsContainer,
            classes.menuItemsContainerDark,
            isDark
          )}
        >
          <a
            href={PageRoutes.Home}
            className={
              selectedIndex === 0 ? classes.selectedMenuItem : undefined
            }
          >
            Főoldal
          </a>
          <a
            href={PageRoutes.Articles}
            className={
              selectedIndex === 1 ? classes.selectedMenuItem : undefined
            }
          >
            Bejegyzések
          </a>
          <a
            href={PageRoutes.Program}
            className={
              selectedIndex === 2 ? classes.selectedMenuItem : undefined
            }
          >
            Program
          </a>
          <a
            href={PageRoutes.Tickets}
            className={
              selectedIndex === 3 ? classes.selectedMenuItem : undefined
            }
          >
            Jegyek
          </a>
          <a
            href={PageRoutes.About}
            className={
              selectedIndex === 4 ? classes.selectedMenuItem : undefined
            }
          >
            A fesztiválról
          </a>
          <a
            href={PageRoutes.Rules}
            className={
              selectedIndex === 5 ? classes.selectedMenuItem : undefined
            }
          >
            Házirend
          </a>
        </div>
      </div>
      {!isDark && (
        <>
          <img
            className={classes.wave}
            alt="wave"
            src="/menu-bar-bottom-part.png"
          />
          <FloatingSocialIcons isOnTop />
        </>
      )}
    </>
  );
};

export default MenuBar;
