import { useEffect, useState } from "react";
import { Activity } from "../model/activity";
import { Category } from "../model/category";
import { toImage } from "../transformers/transformToImage";
import { backendUrl, Endpoints } from "../utils/constants";
import useFetch from "./useFetch";
import { Location } from "../model/location";
import { toTime } from "../utils/helper";

export const useFindAllActivities = (
  dayId?: number,
  locationId?: number,
  favouriteIds?: number[]
) => {
  const { response, error, loading } = useFetch(
    backendUrl +
      Endpoints.Activities +
      "?populate=*" +
      (locationId && locationId !== 0
        ? `&filters[location][id][$eq]=${locationId}`
        : "") +
      (dayId && dayId !== 0 ? `&filters[day][id][$eq]=${dayId}` : "") +
      (favouriteIds && favouriteIds?.length > 0
        ? favouriteIds.reduce((a, b) => a + `&filters[id][$in]=${b}`, "")
        : "")
    // {
    //   headers: new Headers({
    //     Authorization: "Bearer " + process.env.REACT_APP_BEARER_TOKEN,
    //     "Access-Control-Allow-Methods": "GET",
    //   }),
    // }
  );
  const [activities, setActivities] = useState<Activity[]>();

  useEffect(() => {
    if (response) {
      console.log(response.data);
      const activitiesFromResponse: Activity[] = response.data.map(
        (entry: any) => ({
          id: entry.id,
          uid: entry.attributes.uid,
          title: entry.attributes.title,
          subtitle: entry.attributes.subtitle,
          description: entry.attributes.description,
          startTime: toTime(entry.attributes.startTime),
          endTime: toTime(entry.attributes.endTime),
          image: toImage(entry.attributes.image),
          isCommon: entry.attributes.isCommon,
          isConstant: entry.attributes.isConstant,
          day: {
            id: entry.attributes.day.data.id,
            uid: entry.attributes.day.data.attributes.uid,
            theme: entry.attributes.day.data.attributes.theme,
            date: new Date(entry.attributes.day.data.attributes.date),
          },
          category: {
            id: entry.attributes.category.data.id,
            uid: entry.attributes.category.data.uid,
            name:
              entry.attributes.category.data.attributes.name.charAt(0) === " "
                ? entry.attributes.category.data.attributes.name.substr(1)
                : entry.attributes.category.data.attributes.name,
          } as Category,
          location: {
            id: entry.attributes.location.data.id,
            uid: entry.attributes.location.data.attributes.uid,
            title: entry.attributes.location.data.attributes.title,
          } as Location,
        })
      );
      setActivities(activitiesFromResponse);
      setActivities(
        activitiesFromResponse.sort((a, b) => {
          if (a.startTime.hour < b.startTime.hour) {
            return -1;
          }
          if (a.startTime.hour === b.startTime.hour) {
            if (a.endTime.hour < b.endTime.hour) {
              return -1;
            }
            return 1;
          }
          return 1;
        })
      );
    }
  }, [response]);

  return { activities, error, loading };
};
