import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { Article } from "../model/article";
import { themeColors } from "../theme";
import { assignClasses, convertDateToString } from "../utils/helper";
import { PageRoutes } from "../utils/routes";

const useStyles = createUseStyles({
  ahContainer: {
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      cursor: "pointer",
      "& $title": {
        textDecoration: "underline",
        textDecorationColor: themeColors.green,
        fontWeight: 600,
      },
      "& $image": {
        filter: "brightness(0.75)",
        transition: "all 0.2s ease-in",
      },
    },
  },
  ahContainerSmall: { display: "grid", gridTemplateColumns: "140px 1fr" },
  image: {
    borderRadius: "40px 15px 48px 16px",
    objectFit: "cover",
    width: "100%",
    minHeight: 370,
    height: 370,
    "@media only screen and (max-width: 700px)": {
      minHeight: 0,
      height: 248,
    },
  },
  imageSmall: {
    height: 100,
    minHeight: 100,
    borderRadius: 8,
  },
  date: {
    fontSize: 12,
    color: themeColors.blue,
    opacity: 0.5,
    margin: "16px 0 8px 0",
  },
  dateSmall: { margin: "8px 0 2px 0", marginLeft: 16 },
  title: {
    fontSize: 24,
    color: themeColors.blue,
    lineHeight: "32px",
    fontWeight: "600",
    marginBottom: 8,
    marginTop: 0,
    "@media only screen and (max-width: 700px)": {
      fontSize: 18,
      marginTop: -8,
    },
  },
  titleSmall: { fontSize: 16, lineHeight: "20px", marginLeft: 16 },
  description: {
    fontSize: 14,
    color: themeColors.blue,
    lineHeight: 1.5,
    zIndex: 1,
  },
  descriptionSmall: {
    fontSize: 14,
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    marginLeft: 16,
  },
});

interface ArticleHighlightProps {
  article: Article;
  isSmall?: boolean;
}

const ArticleHighlight = (props: ArticleHighlightProps) => {
  const { article, isSmall } = props;
  // console.log(article);
  const navigate = useNavigate();
  const classes = useStyles();
  return isSmall ? (
    <div
      key={`article-${article.id}`}
      className={assignClasses(
        classes.ahContainer,
        classes.ahContainerSmall,
        isSmall
      )}
      onClick={() => {
        navigate(`${PageRoutes.Articles}/${article.id}`);
      }}
    >
      <img
        className={assignClasses(classes.image, classes.imageSmall, isSmall)}
        src={article.image.url}
        alt=""
      />
      <div className={classes.ahContainer}>
        <span
          className={assignClasses(classes.date, classes.dateSmall, isSmall)}
        >
          {convertDateToString(article.date)}
        </span>
        <span
          className={assignClasses(classes.title, classes.titleSmall, isSmall)}
        >
          {article.title}
        </span>
        <span
          className={assignClasses(
            classes.description,
            classes.descriptionSmall,
            isSmall
          )}
        >
          {article.subtitle}
        </span>
      </div>
    </div>
  ) : (
    <div
      className={classes.ahContainer}
      key={`article-${article.id}`}
      onClick={() => {
        navigate(`${PageRoutes.Articles}/${article.id}`);
      }}
    >
      <img className={classes.image} src={article.image.url} alt="" />
      <span className={classes.date}>{convertDateToString(article.date)}</span>
      <span className={classes.title}>{article.title}</span>
      <span className={classes.description}>{article.subtitle}</span>
    </div>
  );
};

export default ArticleHighlight;
