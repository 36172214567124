import { useState } from "react";
import { createUseStyles } from "react-jss";
import { Activity } from "../model/activity";
import { Category } from "../model/category";
import { themeColors } from "../theme";
import { convertTimeToString, useWindowSize } from "../utils/helper";

const useStyles = createUseStyles({
  eventBox: {
    width: "100%",
    height: "100%",
    padding: 8,
    position: "absolute",
    display: "flex",
    flexGrow: 1,
    cursor: "pointer",
    "&:hover $eventBoxInner": { backgroundColor: themeColors.tintYellow },
    "&:hover $title": {
      textDecorationColor: themeColors.blue,
      textDecoration: "underline",
    },
    zIndex: 1,
    "@media only screen and (max-width: 700px)": {
      position: "relative",
      padding: 0,
      marginBottom: 8,
    },
  },
  eventBoxDelayed: {
    marginTop: 36,
    "@media only screen and (max-width: 700px)": {
      marginTop: 0,
    },
  },
  eventBoxInner: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    padding: 6,
    color: themeColors.blue,
    borderRadius: 6,
    maxWidth: "100%",
    border: "1px solid",
    borderColor: "white",
    "@media only screen and (max-width: 700px)": {
      padding: "16px 12px",
    },
  },
  timeSlot: {
    fontSize: 13,
    lineHeight: "14px",
    marginBottom: 4,
    "@media only screen and (max-width: 700px)": {
      fontSize: 14,
      lineHeight: "16px",
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    "@media only screen and (min-width: 701px)": {
      fontSize: 14,
      lineHeight: "15px",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
    },
  },
  titleMini: {
    fontSize: 16,
    fontWeight: 500,
    "@media only screen and (min-width: 701px)": {
      fontSize: 14,
      lineHeight: "15px",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      minHeight: 16,
      marginTop: -4,
      "-webkit-line-clamp": "1 !important",
      "-webkit-box-orient": "vertical",
    },
  },
});

const getColorByCategory = (category: Category): string => {
  switch (category.name) {
    case "Áhitat":
      return "#FFF9E2";
    case "Dicsőítés":
      return "#E3FFE2";
    case "Előadás":
      return "#FFDDC5";
    case "Esti program":
      return "#F6E2FF";
    case "Istentisztelet":
      return "#F9FFE2";
    case "Kerekasztal-beszélgetés":
      return "#E2EAFF";
    case "Koncert":
      return "#FFE2FA";
    case "Műhely":
      return "#EAEAEA";
    default:
      return "#FFFFFF";
  }
};

const getHeight = (activity: Activity) => {
  const shouldBeLonger =
    activity.endTime.minutes === 30 && activity.startTime.minutes === 0;
  const shouldBeShorter =
    activity.endTime.minutes === 0 && activity.startTime.minutes === 30;
  return (
    (activity.endTime.hour - activity.startTime.hour) * 100 +
    (shouldBeLonger ? 50 : 0) +
    (shouldBeShorter ? -40 : 0)
  );
};

interface EventBoxProps {
  activity: Activity;
  onClick: () => void;
}

const EventBox = (props: EventBoxProps) => {
  const { activity, onClick } = props;
  const classes = useStyles();
  const windowSize = useWindowSize();
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className={
        activity.startTime.minutes === 30
          ? `${classes.eventBox} ${classes.eventBoxDelayed}`
          : classes.eventBox
      }
      style={
        activity.isCommon && !windowSize?.isMobile
          ? { height: `${getHeight(activity)}%`, width: "800%" }
          : { height: `${getHeight(activity)}%` }
      }
      onClick={onClick}
    >
      <div
        className={classes.eventBoxInner}
        style={{ backgroundColor: getColorByCategory(activity.category) }}
      >
        <span className={classes.timeSlot}>
          {`${convertTimeToString(activity.startTime)} - ${convertTimeToString(
            activity.endTime
          )}`}
        </span>
        <span
          className={`${classes.title} ${
            getHeight(activity) === 60 && classes.titleMini
          }`}
          style={{
            WebkitLineClamp: getHeight(activity) > 100 ? 5 : 3,
          }}
        >
          {activity.title}
        </span>
      </div>
      {showTooltip && (
        <div
          style={{
            position: "absolute",
            fontSize: 12,
            top: -24,
            display: "flex",
            backgroundColor: themeColors.blue,
            color: themeColors.white,
            padding: "4px 24px",
            borderRadius: "16px 12px 6px 8px",
            fontWeight: 600,
            width: "fit-content",
            minWidth: "max-content",
          }}
        >
          {activity.category.name}
        </div>
      )}
    </div>
  );
};

export default EventBox;
