import { createUseStyles } from "react-jss";
import { themeColors } from "../theme";

const useStyles = createUseStyles({
  textButton: {
    border: "none",
    background: "none",
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer",
    color: themeColors.orange,
    fontFamily: "Ubuntu",
    lineHeight: "24px",
    height: "24px",
    marginTop: "auto",
    "&:hover": {
      textDecoration: "underline",
    },
    "&:active": {
      filter: "brightness(0.75)",
    },
  },
});

interface TextButtonProps {
  children?: React.ReactNode;
  onClick: () => void;
}

const TextButton = (props: TextButtonProps) => {
  const classes = useStyles();
  const { children, onClick } = props;
  return (
    <button className={classes.textButton} onClick={onClick}>
      {children}
    </button>
  );
};

export default TextButton;
