/* eslint-disable jsx-a11y/alt-text */
import { createUseStyles } from "react-jss";
import { themeColors } from "../theme";
import { useWindowSize } from "../utils/helper";
import RegulamentPDF from "../Regulament.pdf";
import AcordParentalPDF from "../AcordParental.pdf";

const useStyles = createUseStyles({
  footer: {
    backgroundColor: themeColors.blue,
    display: "flex",
    padding: 32,
    width: "100vw",
    justifyContent: "center",
    "& span, a": {
      color: themeColors.white,
      fontSize: 14,
    },
  },
  footerContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: 1100,
    "& span": {
      flex: 1,
    },
  },
  footerWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1.75fr 1fr",
    marginTop: 32,
    marginBottom: 128,
    "& > div:nth-of-type(1)": {
      gridColumn: "1 / 1",
      display: "flex",
      maxWidth: 250,
      flexDirection: "column",
      "& > span": {
        fontSize: 14,
        lineHeight: "24px",
        marginBottom: 16,
      },
    },
    "& > div:nth-of-type(2)": {
      gridColumn: "2 / 2",
      display: "flex",
      flexDirection: "column",
      gap: 24,
      "& > span:first-of-type": {
        fontWeight: 500,
        flex: 0,
      },
    },
    "& > div:nth-of-type(3)": {
      gridColumn: "3 / 3",
    },
  },
  contactItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  socialIcons: { display: "flex", gap: 16 },
  copyright: { display: "flex" },
  socialIcon: {
    "&:hover": {
      opacity: 0.75,
      cursor: "pointer",
      transition: "all 0.2s",
    },
  },
});

const Footer = () => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  return (
    <>
      {windowSize?.isMobile ? (
        <>
          <img
            src="/footer-top.svg"
            style={{ marginBottom: -40, width: "100%", position: "relative" }}
          />
          <footer className={classes.footer}>
            <div className={classes.footerContent}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 24,
                  marginBottom: 24,
                }}
              >
                <img
                  src="/IKE-logo.svg"
                  width={35}
                  style={{ marginBottom: 16 }}
                />
                <span style={{ maxWidth: 400, lineHeight: 1.5 }}>
                  Sokszínűség, misszió, ifjúsági munka, önkéntesség,
                  testi-lelki-szellemi nevelés, hovatartozás, Isten és fiatalok,
                  más perspektíva.
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                  marginBottom: 48,
                  marginTop: 32,
                }}
              >
                <span style={{ marginBottom: 8, fontWeight: 500 }}>
                  Kapcsolat
                </span>
                <div className={classes.contactItem}>
                  <img src="/email-icon.svg" height={24} width={24} />
                  <span>fesztival@ike.ro</span>
                </div>
                <div className={classes.contactItem}>
                  <img src="/phone-icon.svg" height={24} width={24} />
                  <span>+4 0744 670 769</span>
                </div>
                <div className={classes.contactItem}>
                  <img src="/location-icon.svg" height={24} width={24} />
                  <span>Kolozsvár, Mócok útja, 84 szám</span>
                </div>
              </div>
              <div style={{ marginBottom: 60 }}>
                {/* <FloatingActionBox variant="default" /> */}
              </div>
              <div className={classes.copyright}>
                <a
                  href={RegulamentPDF}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginBottom: 24, fontSize: 12 }}
                >
                  Általános Szerződési Feltételek, Általalános Adatvédelmi
                  Szabályzat, Házirend
                </a>
              </div>
              <div className={classes.copyright}>
                <a
                  href={AcordParentalPDF}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginBottom: 24 }}
                >
                  Szülői Beleegyező Nyilatkozat
                </a>
              </div>
              <div className={classes.copyright}>
                <span style={{ fontSize: 12 }}>
                  © 2024 Erdélyi IKE. Minden jog fenntartva
                </span>
                <a
                  href="https://www.ike.ro"
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontSize: 12 }}
                >
                  www.ike.ro
                </a>
              </div>
            </div>
          </footer>
        </>
      ) : (
        <>
          <img
            src="/footer-top.svg"
            style={{ marginBottom: -40, width: "100%", position: "relative" }}
          />
          <footer className={classes.footer}>
            <div className={classes.footerContent}>
              <div className={classes.footerWrapper}>
                <div>
                  <img
                    src="/IKE-logo.svg"
                    width={35}
                    style={{ marginBottom: 16 }}
                  />
                  <span>
                    Sokszínűség, misszió, ifjúsági munka, önkéntesség,
                    testi-lelki-szellemi nevelés, hovatartozás, Isten és
                    fiatalok, más perspektíva.
                  </span>
                  <div className={classes.socialIcons}>
                    <img
                      src="/facebook-icon.svg"
                      height={24}
                      width={24}
                      className={classes.socialIcon}
                      onClick={() => {
                        window.open("http://facebook.com/erdelyiike");
                      }}
                    />
                    <img
                      src="/instagram-icon.svg"
                      height={24}
                      width={24}
                      className={classes.socialIcon}
                      onClick={() => {
                        window.open("http://instagram.com/erdelyi.ike");
                      }}
                    />
                    <img
                      src="/youtube-icon.svg"
                      height={24}
                      width={24}
                      className={classes.socialIcon}
                      onClick={() => {
                        window.open(
                          "https://www.youtube.com/channel/UCH3PiAt9HQpuIs-UOwu2TrA"
                        );
                      }}
                    />
                  </div>
                </div>
                <div>
                  <span>Kapcsolat</span>
                  <div className={classes.contactItem}>
                    <img src="/email-icon.svg" height={24} width={24} />
                    <span>fesztival@ike.ro</span>
                  </div>
                  <div className={classes.contactItem}>
                    <img src="/phone-icon.svg" height={24} width={24} />
                    <span>+4 0744 670 769</span>
                  </div>
                  <div className={classes.contactItem}>
                    <img src="/location-icon.svg" height={24} width={24} />
                    <span>Kolozsvár, Mócok útja, 84 szám</span>
                  </div>
                </div>
                <div>{/* <FloatingActionBox variant="default" /> */}</div>
              </div>
              <div className={classes.copyright}>
                <a
                  href={RegulamentPDF}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginBottom: 24 }}
                >
                  Általános Szerződési Feltételek, Általalános Adatvédelmi
                  Szabályzat, Házirend
                </a>
              </div>
              <div className={classes.copyright}>
                <a
                  href={AcordParentalPDF}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginBottom: 24 }}
                >
                  Szülői Beleegyező Nyilatkozat
                </a>
              </div>
              <div className={classes.copyright}>
                <span>© 2024 Erdélyi IKE. Minden jog fenntartva</span>
                <a href="https://www.ike.ro" target="_blank" rel="noreferrer">
                  www.ike.ro
                </a>
              </div>
            </div>
          </footer>
        </>
      )}
    </>
  );
};

export default Footer;
