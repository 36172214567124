import { createUseStyles } from "react-jss";
import { themeColors } from "../theme";

const useStyles = createUseStyles({
  Button: {
    border: "none",
    background: themeColors.orange,
    fontSize: 24,
    fontWeight: 600,
    cursor: "pointer",
    color: themeColors.white,
    fontFamily: "Ubuntu",
    lineHeight: "24px",
    padding: 32,
    height: "24px",
    marginTop: 40,
    flexGrow: 0,
    display: "flex",
    width: "fit-content",
    alignSelf: "center",
    alignItems: "center",
    borderRadius: "22px 12px 18px 24px",
    transition: "all 0.1s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
    "&:active": {
      filter: "brightness(0.75)",
      transform: "scale(1.0)",
    },
  },
});

interface ButtonProps {
  children?: React.ReactNode;
  onClick: () => void;
}

const Button = (props: ButtonProps) => {
  const classes = useStyles();
  const { children, onClick } = props;
  return (
    <button className={classes.Button} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
