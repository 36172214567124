export const backendUrl = process.env.REACT_APP_BACKEND_URL;

export enum Endpoints {
  Authors = "/authors",
  FAQs = "/faqs",
  Articles = "/articles",
  About = "/about",
  Sponsors = "/sponsors",
  Locations = "/locations",
  Activities = "/activities",
  Days = "/days",
  Category = "/categories",
  ConstantActivities = "/constant-activities",
  Map = "/map",
  Review = "/reviews",
}
