import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import MenuBar from "../components/MenuBar";
import Page from "../components/Page";
import { themeColors } from "../theme";
import { PageRoutes } from "../utils/routes";
import ReactMarkdown from "react-markdown";
import Hazirend from "../hazirend.md";

const useStyles = createUseStyles({
  title: {
    fontSize: 32,
    fontWeight: "600",
    alignSelf: "flex-start",
    flexGrow: 1,
    lineHeight: 2,
    color: themeColors.blue,
    "@media only screen and (max-width: 700px)": {
      marginTop: 40,
    },
  },
  rulesContainer: {
    maxWidth: 1280,
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    width: "100%",
    paddingBottom: 80,
    color: themeColors.blue,
    "@media only screen and (max-width: 700px)": {
      padding: 24,
    },
    "& li": {
      marginTop: 8,
      lineHeight: "24px",
    },
  },
  paragraph: {
    color: themeColors.blue,
    fontSize: 18,
    lineHeight: "26px",
  },
  bold: { fontWeight: 600 },
});

const Rules = () => {
  const classes = useStyles();
  const [hazirend, setHazirend] = useState("");

  // Fetch Terms of Use
  useEffect(() => {
    fetch(Hazirend)
      .then((res) => res.text())
      .then((text) => setHazirend(text));
  });

  return (
    <>
      <Page>
        <MenuBar selectedKey={PageRoutes.Rules} />
        <div className={classes.rulesContainer}>
          <ReactMarkdown>{hazirend}</ReactMarkdown>
        </div>
      </Page>
    </>
  );
};

export default Rules;
