import { createUseStyles } from "react-jss";
import { assignClasses } from "../utils/helper";

const useStyles = createUseStyles({
  floatingContainer: {
    position: "absolute",
    top: -60,
    right: 320,
    display: "flex",
    gap: 16,
    cursor: "pointer",
    "& a": {
      cursor: "pointer",
      zIndex: 4,
    },
    "& a:hover": {
      filter: "brightness(0.8)",
      transition: ".2s all",
    },
    "@media only screen and (max-width: 700px)": { right: 100 },
  },
  floatingContainerOnTop: { top: 88, right: 60 },
  instagram: { marginTop: 16 },
});

interface FloatingSocialIconsProps {
  isOnTop?: boolean;
}

const FloatingSocialIcons = (props: FloatingSocialIconsProps) => {
  const classes = useStyles();
  return (
    <div
      className={assignClasses(
        classes.floatingContainer,
        classes.floatingContainerOnTop,
        props.isOnTop
      )}
    >
      <a
        href="https://www.facebook.com/valtsiranyt"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/facebook-icon.png" width={48} height={50} alt="" />
      </a>
      <a
        className={classes.instagram}
        href="https://www.instagram.com/valtsiranyt"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/instagram-icon.png" width={50} height={50} alt="" />
      </a>
    </div>
  );
};

export default FloatingSocialIcons;
