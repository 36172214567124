import { useEffect, useState } from "react";
import { About } from "../model/about";
import { backendUrl, Endpoints } from "../utils/constants";
import useFetch from "./useFetch";

export const useGetAbout = () => {
  const { response, error, loading } = useFetch(
    backendUrl + Endpoints.About
    //   {
    //   headers: new Headers({
    //     Authorization: "Bearer " + process.env.REACT_APP_BEARER_TOKEN,
    //     "Access-Control-Allow-Methods": "GET",
    //   }),
    // }
  );
  const [about, setAbout] = useState<About>();

  useEffect(() => {
    if (!error && response) {
      setAbout({
        description: response.data.attributes.description,
        uid: response.data.attributes.uid,
        phoneNumber: response.data.attributes.phoneNumber,
        email: response.data.attributes.email,
        other: response.data.attributes.other,
      } as About);
    }
  }, [error, response]);

  return { about, error, loading };
};
