import { useEffect, useState } from "react";
import { Day } from "../model/day";
import { backendUrl, Endpoints } from "../utils/constants";
import useFetch from "./useFetch";

export const useFindAllDays = () => {
  const { response, error, loading } = useFetch(
    backendUrl + Endpoints.Days + "?populate=*"
    // {
    //   headers: new Headers({
    //     Authorization: "Bearer " + process.env.REACT_APP_BEARER_TOKEN,
    //     "Access-Control-Allow-Methods": "GET",
    //   }),
    // }
  );
  const [days, setDays] = useState<Day[]>();

  useEffect(() => {
    if (response) {
      const daysFromResponse: Day[] = response.data.map(
        (entry: any): Day => ({
          id: entry.id,
          uid: entry.attributes.uid,
          bibleVerse: entry.attributes.bibleVerse,
          theme: entry.attributes.theme,
          date: new Date(entry.attributes.date),
        })
      );
      setDays(
        daysFromResponse.sort((a, b) => {
          if (a.date < b.date) {
            return -1;
          }
          return 1;
        })
      );
    }
  }, [response]);

  return { days, error, loading };
};
