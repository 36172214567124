import { useEffect, useState } from "react";
import { Author } from "../model/author";
import { ConstantActivity } from "../model/constant-activity";
import { backendUrl, Endpoints } from "../utils/constants";
import useFetch from "./useFetch";

export const useFindAllConstantActivity = () => {
  const { response, error, loading } = useFetch(
    backendUrl + Endpoints.ConstantActivities
    // {
    //   headers: new Headers({
    //     Authorization: "Bearer " + process.env.REACT_APP_BEARER_TOKEN,
    //     "Access-Control-Allow-Methods": "GET",
    //   }),
    // }
  );
  const [constantActivities, setConstantActivities] =
    useState<ConstantActivity[]>();

  useEffect(() => {
    if (response) {
      const activities: ConstantActivity[] = response.data.map(
        (entry: any) =>
          ({
            id: entry.id,
            uid: entry.attributes.uid,
            title: entry.attributes.title,
            description: entry.attributes.description,
          } as ConstantActivity)
      );
      setConstantActivities(
        activities.sort((a, b) => {
          if (a.description.length < b.description.length) {
            return 1;
          }
          return -1;
        })
      );
    }
  }, [response]);

  return { constantActivities, error, loading };
};
