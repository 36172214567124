export enum PageRoutes {
  Home = "/fooldal",
  Articles = "/bejegyzesek",
  About = "/a-fesztivalrol",
  Program = "/program",
  Contact = "/kapcsolat",
  FAQ = "/gyakori-kerdesek",
  Tickets = "/jegyek",
  Rules = "/hazirend",
  Reviews = "/visszajelzes",
  Registration = "/regisztracio",
  Payment = "/fizetes",
}
